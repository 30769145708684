import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
// import Projects from "../../pages/projects";
// import ApacheIceberg from "../../pages/projects/ApacheIceberg";

const LandingPage = lazy(() => import('../../pages/LandingPage'));
const Projects = lazy(() => import('../../pages/projects'));
const ApacheIceberg = lazy(() => import('../../pages/projects/ApacheIceberg'));
const Blogs = lazy(() => import('../../pages/Blogs'));
const MyAppBar = lazy(() => import('../MyAppBar'));

const Routing: React.FC = () =>
    <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/apache-iceberg" element={<ApacheIceberg />} />
        <Route path="/projects/apache-spark" element={<></>} />
        <Route path="/projects/data-visualization" element={<></>} />
        <Route path="/projects/data-analysis" element={<></>} />
        <Route path="/projects/power-bi" element={<></>} />
        <Route path="/projects/tabuleau" element={<></>} />
    </Routes>

export default Routing;